import cx from 'classnames';

import {
  GrantActivityAccordion,
  type GrantActivityAccordionProps,
} from '~/v1/components/accordion/grantActivity/grantActivity';
import { Column } from '~/v1/components/grid/column/column';
import { Grid } from '~/v1/components/grid/grid';

import styles from './grantActivity.module.scss';

interface GrantActivityAccordionModuleProps extends GrantActivityAccordionProps {
  className?: string;
}

export const GrantActivityAccordionModule: React.FC<GrantActivityAccordionModuleProps> = ({
  className,
  ...props
}) => {
  return (
    <Grid className={className}>
      <Column sm={5} md={7} lg={9}>
        <div className={cx(styles.accordion, 'overflowRight', 'overflowLeft')}>
          <GrantActivityAccordion {...props} />
        </div>
      </Column>
    </Grid>
  );
};
