import { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { useInView, useIsPresent } from 'framer-motion';

import { Event, trackEvent } from '~/ui/components/analytics';
import { ANIMATION_DURATION } from '~/v1/components/accordion/grantActivity/grantActivity.constants';
import { Button } from '~/v1/components/button/button';
import { ButtonMode, ButtonSize, ButtonType } from '~/v1/components/button/button.interface';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { Icon } from '~/v1/components/icons//icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { type IImage, ImageBreakpoint } from '~/v1/components/image/image.interface';
import { Link } from '~/v1/components/link/link';
import { SpotlightVideo } from '~/v1/components/spotlight/video/video';

import styles from './card.module.scss';
import { Image } from '../../../image/image';

export interface AccordionCardProps {
  image: IImage;
  videoUrl?: string;
  title: string;
  href: string;
  className?: string;
  eyebrow: string;
}

export function AccordionCard({
  videoUrl,
  image,
  title,
  href,
  className,
  eyebrow,
}: AccordionCardProps): React.ReactElement {
  const [isBackgroundPaused, setIsBackgroundPaused] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);

  const previewVideoRef = useRef<HTMLVideoElement>(null);
  const isPreviewVideoInView = useInView(previewVideoRef);

  const isActiveCard = useIsPresent();

  useEffect(() => {
    if (isActiveCard) {
      setTimeout(() => setEnabled(true), ANIMATION_DURATION * 500);
    } else {
      setEnabled(false);
    }
  }, [isActiveCard]);

  useEffect(() => {
    if (isPreviewVideoInView) {
      previewVideoRef.current?.play();
    } else {
      previewVideoRef.current?.pause();
    }
  }, [isPreviewVideoInView]);

  const toggleBackground = () => {
    setIsBackgroundPaused(!isBackgroundPaused);

    if (isBackgroundPaused) {
      previewVideoRef.current?.play();
    } else {
      previewVideoRef.current?.pause();
    }
  };

  return (
    <FullBleedCTA
      label={title}
      className={cx(styles.card, className)}
      style={{ pointerEvents: enabled ? 'auto' : 'none' }}
    >
      <div className={styles.cardInner}>
        {videoUrl && <SpotlightVideo ref={previewVideoRef} src={videoUrl} isPreview isAccordion />}
        {image && !videoUrl && (
          <Image
            aspectRatio="32/45"
            className={cx(styles.logoWrapper, styles.image)}
            colSpan={{ [ImageBreakpoint.SM]: 5 }}
            src={image.src}
            alt={image.title}
          />
        )}
      </div>
      <div className={styles.cardContent}>
        <div>
          <div className={cx('paragraphTypography', styles.eyebrow)}>{eyebrow}</div>
          <h3 className={cx('modulesTitleTypography truncate-3', styles.title)}>{title}</h3>
        </div>
        <FullBleedCTATrigger>
          <Link
            href={href}
            aria-label="Read the grant story"
            onClick={() =>
              trackEvent(Event.GRANT_ACCORDION_CLICK, {
                accordionTitle: title,
                accordionCTA: 'Read the grant story',
                accordionLink: href,
              })
            }
            aria-disabled={!enabled}
            style={{
              pointerEvents: enabled ? 'auto' : 'none',
            }}
            withIcon
          >
            Read the grant story
          </Link>
        </FullBleedCTATrigger>
      </div>
      {videoUrl && (
        <Button
          isTransparent
          size={ButtonSize.Icon}
          className={styles.button}
          type={ButtonType.Secondary}
          mode={ButtonMode.Light}
          aria-label="Background toggle"
          onClick={toggleBackground}
        >
          <Icon
            className={styles.iconPlayPause}
            type={isBackgroundPaused ? IconType.Play : IconType.Pause}
          />
        </Button>
      )}
    </FullBleedCTA>
  );
}
